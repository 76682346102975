// globalStyles.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root {
  --color-jourve: #fac748;
  --color-jourve-yellow-light: #EADCA6;
  --color-jourve-blue: #68b0ab;
  --color-jourve-dark: #423E37;
  --color-jourve-grey: #6E675F;
  --color-jourve-light: #fcf7ff;

  --color-white: white;
  --color-header: white;
}


* {
  outline: 0;
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {

  background: var(--color-white);
  color: var(--color-jourve-dark);
  font: 400 14px/1.8 'Montserrat', sans-serif;
  overflow-x: hidden;
}
`;

export default GlobalStyle;
