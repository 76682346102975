import styled from 'styled-components';

type Styles = {
  objectPosition?: string;
  objectFit?: string;
  maxWidth?: string;
  width?: string;
  height?: string;
  position?: string;
  top?: string;
  left?: string;
};

interface ImageProps extends Styles {
  src: string;
  alt: string;
}

export const Image: React.FC<ImageProps> = ({
  src = '',
  alt = '',
  ...styles
}) => {
  return <ImageHolder src={src} alt={alt} {...styles} />;
};

const ImageHolder = styled.img<Styles>`
  object-position: ${({ objectPosition }) =>
    objectPosition || 'center'};
  object-fit: ${({ objectFit }) => objectFit || 'cover'};
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || 'auto'};
  position: ${({ position }) => position || 'static'};
  top: ${({ top }) => top || '0'};
  left: ${({ left }) => left || '0'};
`;
