import React from 'react';
import styled from 'styled-components';

interface Styles {
  margin?: string;
  padding?: string;
}

interface Props extends Styles {
  children: React.ReactNode;
}

export const Block: React.FC<Props> = ({ children, ...styles }) => (
  <Holder {...styles}>{children}</Holder>
);

const Holder = styled.div<Styles>`
  margin: ${({ margin }) => margin || '0'};
  padding: ${({ padding }) => padding || '0'};
`;
