import React, { useState } from 'react';
import styled from 'styled-components';
import WhiteIcon from '../../Icons/SmallWhite';
import BlackIcon from '../../Icons/SmallBlack';

type NavbarProps = {
  show: boolean;
  onShowToggle: () => void;
};

export const HeaderNavbar: React.FC<NavbarProps> = ({
  show,
  onShowToggle,
}) => {
  const [fixed, setFixed] = useState(false);
  return (
    <Navbar id="nav-bar-jourve">
      <LogoHolder show={show}>
        {show && <WhiteIcon />}
        {fixed && !show && <BlackIcon />}
      </LogoHolder>
      <span
        className="box-primary-nav-trigger"
        onClick={onShowToggle}
      >
        <MenuIcon show={show} fixed={fixed} />
      </span>
    </Navbar>
  );
};

const Navbar = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  z-index: 9;
  background: transparent;
  backface-visibility: hidden;
  transition: transform 0.5s;

  &.is-fixed {
    position: fixed;
    top: 0;
    transform: translate3d(0, -100%, 0);
    background: rgba(255, 255, 255, 0.96);
    transition: transform 0.5s;
  }

  &.is-fixed.is-visible {
    transform: translate3d(0, 0, 0);
    transition: transform 0.5s;
  }

  &.menu-opened {
    background-color: transparent;
  }

  & .box-primary-nav-trigger {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 50px;
    background-color: var(--color-jourve-light);
    cursor: pointer;
  }

  & .box-primary-nav-trigger {
    width: 100px;
    padding-left: 1em;
    background-color: transparent;
    height: 30px;
    line-height: 30px;
    right: 50px;
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
  }

  & .box-primary-nav-trigger .box-menu-text {
    display: inline-block;
  }

  & .box-primary-nav-trigger .box-menu-icon {
    left: auto;
    right: 1em;
    transform: translateX(0) translateY(-50%);
  }
`;

const MenuIcon = styled.span<{ show: boolean; fixed: boolean }>`
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  transform: translateX(-50%) translateY(-50%);
  width: 35px;
  height: 2px;
  background-color: ${({ show, fixed }) =>
    show
      ? 'rgba(255, 255, 255, 0)'
      : fixed
      ? 'var(--color-jourve-dark)'
      : 'var(--color-header)'};
  transition: background-color 0.3s;
  list-style: none;

  &::before,
  &::after {
    content: '';
    width: 80%;
    height: 100%;
    position: absolute;
    background-color: var(--color-header);
    right: 0;
    transition: top 0.3s, background-color 0s, -webkit-transform 0.3s;
    transition: transform 0.3s, top 0.3s, background-color 0s;
    transition: transform 0.3s, top 0.3s, background-color 0s,
      -webkit-transform 0.3s;
  }

  &::before {
    top: ${({ show }) => (show ? '0' : '-5px')};
    transform: ${({ show }) => (show ? 'rotate(135deg)' : '0')};
  }

  &::after {
    top: ${({ show }) => (show ? '0' : '5px')};
    transform: ${({ show }) => (show ? 'rotate(225deg)' : '0')};
  }
`;

const LogoHolder = styled.div<{ show: boolean }>`
  display: block;
  padding: 20px;
  margin-left: 50px;
  opacity: ${({ show }) => (show ? '1' : '0')};
  transition: opacity 0.5s;
`;
